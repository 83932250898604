import { NearbyTheatreContainer } from "./FindNearbyTheatre.styles";

const FindNearbyTheatre = ({ cityJson }: any) => {
  const handleBookNow = () => {
    //navigate("private-theaters-booking");
    const element = document.getElementById("our-branches");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" }); // Scroll smoothly to the section
    }
  };
  return (
    <>
      <NearbyTheatreContainer>
        <h1 className="title">
          Celebrate in Style at {cityJson.name}'s <br />
          Best Private Theaters
        </h1>
        <h2 className="subtitle">
          Choose from multiple branches across {cityJson.name} <br />
          for unforgettable celebrations
        </h2>
        <a onClick={handleBookNow} className="findButton">
          Find Your Nearest Theatre
        </a>
      </NearbyTheatreContainer>
      <a onClick={handleBookNow} className="findButton_mobile">
        Find Your Nearest Theatre
      </a>
    </>
  );
};

export default FindNearbyTheatre;
