import hyderabadBg from "./assets/images/hyderabad/banner.png";
import delhibg from "./assets/images/delhi/bg.JPG";
import bangalorebg from "./assets/images/bangalore/bg.jpg";
import MiyaPur from "./assets/images/hyderabad/Miyapur.jpg";
import Suchitra from "./assets/images/hyderabad/Suchitra.jpg";
import Himayatnagar from "./assets/images/hyderabad/Himayatnagar.jpg";
import LaxmiNagar from "./assets/images/delhi/LaxmiNagar.jpg";
import Saket from "./assets/images/delhi/Saket.jpg";
import Janakpuri from "./assets/images/delhi/Janakpuri.jpg";
import KarolBagh from "./assets/images/delhi/KarolBagh.jpg";
import Rajaji from "./assets/images/bangalore/Rajaji.jpg";
import Koramangala from "./assets/images/bangalore/Koramangala.jpg";
import Nagavara from "./assets/images/bangalore/Nagavara.jpg";
import Whitefield from "./assets/images/bangalore/Whitefield.jpg";
import Banashankari from "./assets/images/bangalore/Banashankari.jpg";

export const CITY_JSON = {
  hyderabad: {
    name: "Hyderabad",
    bg: hyderabadBg,
    branches: [
      {
        name: "Jublee Hills",
        address:
          "1st Floor, Plot #769, Rd Number 44, behind IMI Mobiles, near State Art Gallery, CBI Colony, Jubilee Hills, Hyderabad, Telangana 500033",
        mapLink: "https://maps.app.goo.gl/Myw493HmCoQGuQf19",
        image: hyderabadBg,
        theatreLink:
          "https://thebingetown.com/private-theatres-in-hyderabad/private-celebration-venue-in-jubilee%20hills",
        theatres: 4,
      },
      {
        name: "Miyapur",
        address:
          "1st Floor, 2-83, NH 65, above Bandhan Bank, Chanda Nagar, Miyapur, Hyderabad, Telangana 500050",
        mapLink: "https://maps.app.goo.gl/xcPQURqNZVLMWrjw8",
        image: MiyaPur,
        theatreLink:
          "https://thebingetown.com/private-theatres-in-hyderabad/private-celebration-venue-in-miyapur",
        theatres: 3,
      },
      {
        name: "Himayatnagar",
        address:
          "3rd Floor, Above Hot Tracks, Near Cafe Bahar, Raja Reddy Marg, Himayatnagar, Hyderabad, Telangana 500029",
        mapLink: "https://maps.app.goo.gl/UmTGiwbyEwhMZnzMA",
        image: Himayatnagar,
        theatreLink:
          "https://thebingetown.com/private-theatres-in-hyderabad/private-celebration-venue-in-himayatnagar",
        theatres: 3,
      },
      {
        name: "Suchitra",
        address:
          "Plot #20, Venkateshwara Colony, Besides Anutex, Suchitra Rd, Hyderabad, Telangana 500067",
        mapLink: "https://maps.app.goo.gl/849vpvyGMk79TdLa8",
        image: Suchitra,
        theatreLink:
          "https://thebingetown.com/private-theatres-in-hyderabad/private-celebration-venue-in-suchitra%20circle",
        theatres: 2,
      },
    ],
    metadata: {
      "@context": "https://schema.org",
      "@type": "LocalBusiness",
      name: "The Binge Town - Hyderabad Private Theaters",
      description:
        "Experience luxury and privacy at The Binge Town's private theaters in Hyderabad. Book your celebrations today!",
      telephone: "+91-8618976974",
      image: "https://thebingetown.com/hyderabad-landing-page.jpg",
      priceRange: "$",
      url: "https://thebingetown.com/hyderabad-private-theatres",
      sameAs: [
        "https://facebook.com/thebingetown",
        "https://www.instagram.com/bingetown_in",
      ],
      logo: "https://www.thebingetown.com/TBT.jpeg",
    },
  },
  delhi: {
    name: "Delhi",
    bg: delhibg,
    branches: [
      {
        name: "Janakpuri",
        address:
          "3rd Floor, #3, above Her Fitness, opp. Mata Chanan Devi Hospital, Block C1, Janakpuri, Delhi, 110058",
        mapLink: "https://maps.app.goo.gl/Cuw2WVxt6EBYdeNQA",
        image: Janakpuri,
        theatreLink:
          "https://thebingetown.com/private-theatres-in-delhi/private-celebration-venue-in-janakpuri",
        theatres: 4,
      },
      {
        name: "Laxmi Nagar",
        address:
          "3rd Floor, D-32, near Laxmi Nagar Metro Station, above Domino's, Delhi, 110092",
        mapLink: "https://maps.app.goo.gl/aHKhdT3wqun4ZfNeA",
        image: LaxmiNagar,
        theatreLink:
          "https://thebingetown.com/private-theatres-in-delhi/private-celebration-venue-in-laxmi%20nagar",
        theatres: 6,
      },
      {
        name: "Saket",
        address:
          "Ground Floor, K261, Lane No 5, Westend Marg, Saiyad Ul Ajaib Extension, Saket, New Delhi, Delhi 110030",
        mapLink: "https://maps.app.goo.gl/AHP1BBRWEZ3okLx28",
        image: Saket,
        theatreLink:
          "https://thebingetown.com/private-theatres-in-delhi/private-celebration-venue-in-saket",
        theatres: 5,
      },
      {
        name: "Karol Bagh",
        address:
          "1st Floor, 3C/8, New Rohtak Rd, opp. Liberty Cinemas, Block 3C, Karol Bagh, New Delhi, Delhi, 110005",
        mapLink: "https://maps.app.goo.gl/jsEJuSbBv2HbmXPW9",
        image: KarolBagh,
        theatreLink:
          "https://thebingetown.com/private-theatres-in-delhi/private-celebration-venue-in-karol%20bagh",
        theatres: 6,
      },
    ],
    metadata: {
      "@context": "https://schema.org",
      "@type": "LocalBusiness",
      name: "The Binge Town - Private Theaters in delhi",
      description:
        "Experience luxury and privacy at The Binge Town's private theaters in Delhi. Book your celebrations today!",
      address: {
        "@type":
          "1Ground Floor, K261, Lane No 5, Westend Marg, Saiyad Ul Ajaib Extension, Saket, New Delhi, Delhi 110030",
        addressCountry: "IN",
      },
      telephone: "+91-8618976974",
      image: "https://thebingetown.com/Del-landing-page.jpg",
      priceRange: "$",
      url: "https://thebingetown.com/private-theatres-in-delhi",
      sameAs: [
        "https://facebook.com/thebingetown",
        "https://www.instagram.com/bingetown_in",
      ],
    },
  },
  bengaluru: {
    name: "Bengaluru",
    bg: bangalorebg,
    branches: [
      {
        name: "Koramangala",
        address:
          "SM Complex, Hosur Rd, Madiwala, Santhosapuram, Koramangala 2nd Block, Koramangala, Bengaluru, Karnataka 560068",
        mapLink: "https://maps.app.goo.gl/4HMF7Rvaf56g4fRY7",
        image: Koramangala,
        theatreLink:
          "https://thebingetown.com/private-theatres-in-bangalore/private-celebration-venue-in-koramangala",
        theatres: 2,
      },
      {
        name: "Rajaji Nagar",
        address:
          "Ram Mandir, 1st Floor, Shree Lakshmi, 59th Cross Road, Road, 4th Block, Rajajinagar, Bengaluru, Karnataka 560010",
        mapLink: "https://maps.app.goo.gl/9Kyx2X6D3ceWH4Yq5",
        image: Rajaji,
        theatreLink:
          "https://thebingetown.com/private-theatres-in-bangalore/private-celebration-venue-in-rajaji%20nagar",
        theatres: 3,
      },
      {
        name: "Banashankari",
        address:
          "1st Floor, SLV Arcade, 100 Feet Ring Road, above Sangeetha Mobiles, 5th Phase, Banashankari 3rd Stage, Banashankari, Bengaluru, Karnataka 560085",
        mapLink: "https://maps.app.goo.gl/Abo1HjxTT2fRTRjF8",
        image: Banashankari,
        theatreLink:
          "https://thebingetown.com/private-theatres-in-bangalore/private-celebration-venue-in-banashankari",
        theatres: 3,
      },
      {
        name: "Whitefield",
        address:
          "2nd Floor, 10/1, Behind Detailing Mafia Graphite India Road, Hoodi, Whitefield, Bengaluru, Karnataka 560048",
        mapLink: "https://maps.app.goo.gl/Xp14xRXn63HV3n9A9",
        image: Whitefield,
        theatreLink:
          "https://thebingetown.com/private-theatres-in-bangalore/private-celebration-venue-in-whitefield",
        theatres: 3,
      },
      {
        name: "Nagavara",
        address:
          "3rd Floor, FJ Complex, 3rd Cross Rd, above Reliance Smart Point, Mariyannapalya, Nagavara, Bengaluru, Karnataka 560025",
        mapLink: "https://maps.app.goo.gl/xXV2oKBQaN3XojEf7",
        image: Nagavara,
        theatreLink:
          "https://thebingetown.com/private-theatres-in-bangalore/private-celebration-venue-in-nagavara",
        theatres: 3,
      },
    ],
    metadata: {
      "@context": "https://schema.org",
      "@type": "LocalBusiness",
      name: "The Binge Town - Private Theaters in bengaluru",
      description:
        "Experience luxury and privacy at The Binge Town's private theaters in Bengaluru. Book your celebrations today!",
      address: {
        "@type":
          "10/1B, Graphite India Road, Hoodi Village, KR Puram, Bengaluru,560048, Karnataka, India",
        addressLocality: "Bengaluru",
        addressCountry: "IN",
      },
      telephone: "+91-8618976974",
      image: "https://thebingetown.com/Blr-landing-page.jpg",
      priceRange: "$",
      url: "https://thebingetown.com/private-theatres-in-bengaluru",
      sameAs: [
        "https://facebook.com/thebingetown",
        "https://www.instagram.com/bingetown_in",
      ],
    },
  },
};
