import React from "react";
import styled from "styled-components";

// Styled component for the floating icon
const FloatingWhatsAppContainer = styled.div`
  position: sticky; /* Ensure it stays fixed on the screen */
  left: 95vw; /* 20px from the right side */
  bottom: 20px; /* 20px from the bottom */
  z-index: 1000; /* Ensures it's above other elements */
  width: 80px;
  height: 80px;
  background-color: #25d366;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
  will-change: transform; /* Optimize for performance */

  img {
    width: 80px;
    height: 80px;
  }

  @media (max-width: 768px) {
    width: 70px; /* Smaller icon for mobile */
    height: 70px;
    left: 85vw; /* Adjust for mobile */
    bottom: 30px;

    img {
      width: 70px;
      height: 70px;
    }
  }
`;

const WhatsAppIcon = styled.img`
  width: 100%;
  height: 100%;
  padding: 10px;
  border-radius: 90%;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.1);
  }
`;

const FloatingWhatsApp = () => {
  return (
    <FloatingWhatsAppContainer>
      <a
        href="https://api.whatsapp.com/send?phone=918618976974&text=Hello%20Binge%20Town!"
        target="_blank"
        rel="noopener noreferrer"
      >
        <WhatsAppIcon
          src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
          alt="WhatsApp"
        />
      </a>
    </FloatingWhatsAppContainer>
  );
};

export default FloatingWhatsApp;
