import { useNavigate, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";

import * as S from "./PaymentPage.style";
import {postOrder} from "../../../../apis/ConfigAPI";
import useRazorpay from "react-razorpay";
import Header from "../../../Home/screens/Header/Header";
import CopyRight from "../../../Home/screens/CopyRight/CopyRight";

const PaymentPage = () => {
    const location = useLocation();
    const [Razorpay] = useRazorpay();
    const queryParams = new URLSearchParams(location.search);
    const order_id = queryParams.get('order_id');  // Example query param

    const [allDetails, setAllDetails] = useState<any>({}) || {};

    useEffect(() => {
        const fetchData = async () => {
            console.log("Details are ",allDetails);
            if(!allDetails || Object.keys(allDetails).length == 0){
                let details = await postOrder(order_id)
                if(!details){
                    alert(`Error while getting order details`);
                    return;
                }
                setAllDetails(details);
                console.log("Details are ",details);
            }
        }
       fetchData();
    }, []);

    const handleNext = async () => {
        const handlePaymentRazorpay = async (
            orderId: any,
            amt: any,
            userDetails: any
        ) => {
            const options: any = {
                key: "rzp_live_GQXH6iqba8bndz", // Enter the Key ID generated from the Dashboard
                amount: amt?.toString(), // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
                currency: "INR",
                name: "Bcks Franchise Llp",
                description: "Advance payment for Binge Town Slot",
                image: "https://example.com/your_logo",
                order_id: orderId, //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
                handler: async (response: {
                    razorpay_payment_id: any;
                    razorpay_order_id: any;
                    razorpay_signature: any;
                }) => {
                    window.location.replace(
                        "https://thebingetown.com/successBooking"
                    );
                },
                modal: {
                    escape: false,
                    ondismiss: () => {
                        console.log("opopopopopopop");
                    },
                },
                prefill: userDetails,
                notes: {
                    // code: `${finalBookObj.code}_${finalBookObj.date}`,
                },
                theme: {
                    color: "#3399cc",
                },
            };
            console.log("Options are ", options);
            window.scrollTo(0, 0);
            const rzp1 = new Razorpay(options);

            rzp1.on(
                "payment.failed",
                function (response: {
                    error: {
                        code: any;
                        description: any;
                        source: any;
                        step: any;
                        reason: any;
                        metadata: { order_id: any; payment_id: any };
                    };
                }) {
                    window.location.replace(
                        "https://thebingetown.com/failedBooking"
                    );
                }
            );
            rzp1.open();
        };
        handlePaymentRazorpay(allDetails.order_id, allDetails?.advancePaid, {
            name: allDetails.name,
            email: allDetails.email,
            contact: allDetails.phoneNum,
        });
    }

    if(!allDetails){
        return (<div>Loading...</div>);
    }

    return (
        <>
            <title>The Binge Town | Booking Overview</title>
            <Header />
            <S.BookingSummaryContainer>
                <S.Heading>Booking summary</S.Heading>
                <S.PriceBreakup>
                    <S.DetailPriceBreakup>
                        <S.Section>
                            <S.SectionTxt>
                                {allDetails.theatre} ({allDetails?.numOfPeople} People)
                            </S.SectionTxt>
                            <S.SectionPrice>&#8377; {allDetails?.theatrePrice}</S.SectionPrice>
                        </S.Section>
                        {allDetails?.decorType !== "None" ? (
                            <S.Section>
                                <S.SectionTxt>Decoration</S.SectionTxt>
                                <S.SectionPrice>&#8377; {allDetails?.decorPrice}</S.SectionPrice>
                            </S.Section>
                        ) : null}

                        {allDetails.cakeAry &&
                            allDetails.cakeAry.map((cake: { name: String; price: number }) => {
                                return (
                                    <S.Section>
                                        <S.SectionTxt>{cake?.name} </S.SectionTxt>
                                        <S.SectionCount> X 1</S.SectionCount>
                                        <S.SectionPrice>&#8377; {cake.price}</S.SectionPrice>
                                    </S.Section>
                                );
                            })}

                        {allDetails.addOnAry &&
                            allDetails.addOnAry.map((addon: { name: String; price: number, quantity:String }) => {
                                if(addon.name.includes("Fog Entry")){
                                  return (
                                    <S.Section>
                                      <S.SectionTxt>{addon.name} </S.SectionTxt>
                                      <S.SectionCount> X {addon.quantity}</S.SectionCount>
                                      <S.SectionPrice>&#8377; {addon.price}</S.SectionPrice>
                                    </S.Section>
                                  );
                                }
                                return (
                                    <S.Section>
                                        <S.SectionTxt>{addon.name} </S.SectionTxt>
                                        <S.SectionCount> X 1</S.SectionCount>
                                        <S.SectionPrice>&#8377; {addon.price}</S.SectionPrice>
                                    </S.Section>
                                );
                            })}
                        {allDetails?.coupon !=="" ?(
                           <S.Section>
                            <S.SectionTxt>Discount applied</S.SectionTxt>
                            <S.SectionPrice> -  &#8377;{allDetails.couponValue}</S.SectionPrice>
                           </S.Section> 
                        ):null}
                    </S.DetailPriceBreakup>
                    <div style={{ borderBottom: "1px solid #C8C8C8" }}></div>

                    <S.SubtotalContainer>
                        <S.Section>
                            <S.SectionTxt>Subtotal</S.SectionTxt>
                            <S.SectionPrice>&#8377; {allDetails.totalPrice}</S.SectionPrice>
                        </S.Section>
                    </S.SubtotalContainer>
                    <div style={{ borderBottom: "1px solid #C8C8C8" }}></div>

                    <S.AdvancePaymentContainer>
                        <S.Section>
                            <S.AmountPayableTxt>Advance amount payable</S.AmountPayableTxt>
                            <S.SectionPrice>&#8377; {allDetails.advancePaid}</S.SectionPrice>
                        </S.Section>
                    </S.AdvancePaymentContainer>
                    <div style={{ borderBottom: "1px solid #C8C8C8" }}></div>

                    <S.BalanceAmountContainer>
                        <S.Section>
                            <S.BalanceAmount>
                                <S.SectionTxt>Balance amount</S.SectionTxt>
                                <S.SectionSubtxt>(Payable at the venue)</S.SectionSubtxt>
                            </S.BalanceAmount>

                            <S.SectionPrice>
                                &#8377;{" "}
                                {allDetails.totalPrice - allDetails.advancePaid}
                            </S.SectionPrice>
                        </S.Section>
                    </S.BalanceAmountContainer>
                </S.PriceBreakup>

                <S.FooterView>
                        <>
                                <S.ButtonView
                                    type="button"
                                    onClick={handleNext}
                                    disabled={false}
                                >
                                    Pay &#8377; {allDetails.advancePaid}
                                </S.ButtonView>
                        </>
                </S.FooterView>
            </S.BookingSummaryContainer>
            <CopyRight />
        </>
    );
};

export default PaymentPage;
