import styled from "styled-components";

import "./MainContainer.styled.css";
import FindNearbyTheatre from "./Components/FindNearbyTheatre";
import CardComponent from "./Components/Card/Card";

const CardsContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  justify-content: center;
  .float-cards-container {
    transform: translate(0%, -20%);
    bottom: 0;
    left: 50%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    height: 100%;
    flex-wrap: wrap;
    gap: 40px;
    justify-content: center;
  }

  @media (max-width: 1020px) {
    .float-cards-container {
      grid-template-columns: repeat(1, 1fr);
      position: static;
      transform: translate(0%);
      margin: 40px auto;
      width: 80%;
      justify-items: center;
    }
  }
`;

const MainContainerView = styled.div`
  position: relative;
  overflow: hidden;
  height: calc(100vh - 100px);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  .findButton_mobile {
    display: none;
    background-color: #ffb80c;
    color: #000;
    font-size: 20px;
    position: absolute;
    bottom: 10%;
    right: 50%;
    font-weight: bold;
    border-radius: 40px;
    padding: 16px 15px;
    white-space: nowrap;
    border: none;
    outline: none;
    cursor: pointer;
    box-shadow: inset 0px -8px 0px rgb(177 100 0 / 30%);
    text-decoration: none;
  }

  @media (max-width: 1020px) {
    .findButton_mobile {
      display: block;
      transform: translate(50%);
    }
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    margin: 0%;
  }
`;

const MainContainer = ({ cityJson }: any) => {
  return (
    <>
      <MainContainerView>
        <img
          className="zoom-in-out-box"
          src={cityJson.bg}
          alt="Book_now"
          title="Book_now"
          //loading="lazy"
          style={{
            objectFit: "cover",
            overflow: "hidden",
            width: "100%",
            height: "100%",
          }}
        />

        <FindNearbyTheatre cityJson={cityJson} />
      </MainContainerView>
      <CardsContainer>
        <div className="float-cards-container">
          <CardComponent />
          <CardComponent
            icon="💍"
            title="Anniversary"
            description="Mark your milestone anniversary with a romantic and intimate private theater celebration."
          />
          <CardComponent
            icon="🎉"
            title="Party & Events"
            description="Whether it's a reunion, farewell, or any other social gathering, our private theaters provide a unique and exclusive venue"
          />
        </div>
      </CardsContainer>
    </>
  );
};
export default MainContainer;
